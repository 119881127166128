export const Loading = ({ className }: { className?: string }) => {
  return (
    <div className={className}>
      <div className="flex justify-center">
        <div className="w-2 h-2 bg-green-600 rounded-full animate-ping" />
        <div className="mx-4 w-2 h-2 bg-green-600 rounded-full animate-ping" />
        <div className="w-2 h-2 bg-green-600 rounded-full animate-ping" />
      </div>
    </div>
  );
};
