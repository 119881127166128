import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Loading } from "./components/common/Loading";

const Layout = lazy(() => import("./pages/Layout"));
const CardDetailPage = lazy(() => import("./pages/CardDetailPage"));
const CardListPage = lazy(() => import("./pages/CardListPage"));
const HoldersPage = lazy(() => import("./pages/HoldersPage"));

function App() {
  return (
    <Suspense
      fallback={
        <Loading className="flex justify-center items-start pt-20 min-h-screen" />
      }
    >
      <Routes>
        <Route element={<Layout />}>
          <Route index element={<CardListPage className="pb-4" />} />
          <Route
            path="detail/:id"
            element={<CardDetailPage className="pb-4" />}
          />
          <Route path="holders" element={<HoldersPage className="pb-4" />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
